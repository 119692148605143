.bio-text {
    font-size: .9rem;
    font-weight: 400;
}

.list-text {
    font-size: .8rem;
}

.list-subtext {
    font-size: .65rem;
}